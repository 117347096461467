import PropTypes from 'prop-types'
import Image from 'next/image';
import { Tagline } from '@/components/content/tagline'
import { CallToAction } from '@/components/content/call-to-action'
import { Linker } from '@/components/content/linker'

Card.propTypes = {
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  imageBlur: PropTypes.string,
  imageAltText: PropTypes.string,
  callToAction: PropTypes.any,
}

const ImageComponent = ({ image, imageBlur, imageAltText, url }) => {
  if (url) {
    return (<div className="mb-6">
      <Linker url={url}>
        <Image
          src={image}
          alt={imageAltText || ''}
          blurDataURL={imageBlur || ''}
          placeholder={imageBlur ? 'blur' : 'empty'}
          layout="intrinsic"
          width={560}
          height={320}
          className="block object-cover object-center w-full rounded lg:h-60 xl:h-56 md:h-64 sm:h-72 xs:h-72 h-72 aspect-w-16 aspect-h-9 lg:aspect-none"
        />
      </Linker>
    </div>
    )
  } else {
    return (<div className="mb-6">
      <Image
        src={image}
        alt={imageAltText || ''}
        blurDataURL={imageBlur || ''}
        placeholder={imageBlur ? 'blur' : 'empty'}
        layout="intrinsic"
        width={560}
        height={320}
        className="block object-cover object-center w-full rounded lg:h-60 xl:h-56 md:h-64 sm:h-72 xs:h-72 h-72 aspect-w-16 aspect-h-9 lg:aspect-none"
      />
    </div>
    )
  }
}

const TitleComponent = ({ title, url }) => {
  if (url) {
    return <Linker url={url}><h2 className="inline-block mb-2 text-lg font-medium text-gray-900 title-font">{title}</h2></Linker>
  } else {
    return <h2 className="inline-block mb-2 text-lg font-medium text-gray-900 title-font">{title}</h2>
  }
}

export function Card(props: { tagline: string; title: string; text: string; url: string; image: string; imageBlur: string; imageAltText: string, callToAction: any }) {

  const { title, tagline, text, url, image, imageBlur, imageAltText, callToAction } = props

  function TopMargin(type: string) {
    if (type === 'buttonIcon' || type === 'button') {
      return 'mt-4'
    } else {
      return 'mt-3'
    }
  }

  return (
    <div className='flex flex-col'>
      {/* Image */}
      {image &&
        <ImageComponent image={image} imageBlur={imageBlur} imageAltText={imageAltText} url={url} />
      }

      {/* Tagline */}
      {tagline && <Tagline text={tagline} size='sm' theme='light' classes='' />}

      {/* Title */}
      {title &&
        <TitleComponent title={title} url={url} />
      }

      {/* Text */}
      {text &&
        <p className="text-base leading-relaxed text-gray-700">{text}</p>
      }

      {/* CTA */}
      {(callToAction && callToAction?.type) && <div className='mt-auto'><div className={`${TopMargin(callToAction?.type)}`}><CallToAction title={callToAction?.title} url={callToAction?.url} type={callToAction?.type} icon={callToAction?.icon?.name} style={`cta`} size={`sm`} theme={`light`} /></div></div>}
    </div>
  )
}

/* eslint-disable react-hooks/rules-of-hooks */
import { capitalizeFirstLetter } from '@/utils/helpers'
import { useRef, useState,  ChangeEvent } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import { Teaser } from '@/components/content/view/rwr/training-library/teaser'
import { Accordion } from '@/components/content/accordion'

export function TrainingLibrary({ view }) {
  // console.log({view})


  if (!view?.courses || view?.courses?.length < 1) {
    return
  }
  // console.log({view})
  const [courses, setCourses] = useState(view?.courses)
  // console.log({ courses })
  const [filterText, setFilterText] = useState(null)
  const [selectedLOD, setSelectedLOD] = useState([])
  const [selectedFos, setSelectedFos] = useState([])
  const [selectedFormat, setSelectedFormat] = useState([])
  const [showFilterPanel, setShowFilterPanel] = useState(false)
  const ref = useRef(null)
  const entry = useIntersectionObserver(ref, {
    root: null,
    rootMargin: "0px 0px -100%",
    // rootMargin: "0px 0px -60px",
    threshold: 0
  })
  const isVisible = !!entry?.isIntersecting

  const filteredCourses = view?.courses.filter(course => {
    return filterText ? course?.title?.toLowerCase()?.includes(filterText.toLowerCase()) || course?.description?.toLowerCase()?.includes(filterText.toLowerCase()) : course &&

      (selectedFos?.length > 0 ? selectedFos.includes(course?.fieldOfStudy?._id): course) &&

      (selectedFormat?.length > 0 ? course?.formats?.find((format: { _id: any[][] }) => format._id.includes(selectedFormat)) : course) &&

      ((selectedLOD?.length > 0 && selectedLOD?.length === 1) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[0])) : course) &&
      ((selectedLOD?.length > 0 && selectedLOD?.length === 2) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[1])) : course) &&
      ((selectedLOD?.length > 0 && selectedLOD?.length === 3) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[2])) : course) &&
      ((selectedLOD?.length > 0 && selectedLOD?.length === 4) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[3])) : course) &&
      ((selectedLOD?.length > 0 && selectedLOD?.length === 5) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[4])) : course) &&
      ((selectedLOD?.length > 0 && selectedLOD?.length === 6) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[5])) : course) &&
      ((selectedLOD?.length > 0 && selectedLOD?.length === 7) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[6])) : course) &&
      ((selectedLOD?.length > 0 && selectedLOD?.length === 8) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[7])) : course) &&
      ((selectedLOD?.length > 0 && selectedLOD?.length === 9) ? course?.trackLevel?.find((trackLevel) => trackLevel.includes(selectedLOD[8])) : course)

      // (selectedLOD?.length > 0 ? selectedLOD.find((level) => level.includes(course?.trackLevel)) : course)

  })
  // console.log({courses})
  const FosList = view?.fieldOfStudy;


  const LODList = [
    { title: 'Reliability Eng. - Introductory', id: 'reliability-engineer-introductory' },
    { title: 'Reliability Eng. - Intermediate', id: 'reliability-engineer-intermediate' },

    { title: 'Rotating Equipment Eng. - Introductory', id: 'rotating-equipment-engineer-introductory' },
    { title: 'Rotating Equipment Eng. - Intermediate', id: 'rotating-equipment-engineer-intermediate' },
    { title: 'Rotating Equipment Eng. - Advanced', id: 'rotating-equipment-engineer-advanced' },
    { title: 'Rotating Equipment Eng. - Expert', id: 'rotating-equipment-engineer-expert' },

    { title: 'C.M. Specialist - Introductory', id: 'condition-monitoring-specialist-introductory' },
    { title: 'C.M. Specialist - Intermediate', id: 'condition-monitoring-specialist-intermediate' },
    { title: 'C.M. Specialist - Advanced', id: 'condition-monitoring-specialist-advanced' },
  ]

  const AvailableFormats = [
    // { _id: 'liveOnlineCourses', title: 'Live Online Courses' }
    { _id: 'liveInPersonCourses', title: 'Live In-Person Courses' },
    { _id: 'privateOnSite', title: 'Private On-Site' },
    { _id: 'privateOnline', title: 'Private Online' },
    { _id: 'onDemandCourse', title: 'On-Demand Course' },
    { _id: 'memberOnlyTraining', title: 'Member Only Training' },
    // { _id: 'memberOnlyLiveTrainings', title: 'Member Only Live Training' },
    // { _id: 'memberOnlyPreRecordedTraining', title: 'Member Only Pre-Recorded Training' }
  ].sort()

  const handleSetLOD = (e: ChangeEvent<HTMLInputElement>) => {
    let newVal = e.target.value
    let isChecked = e.target.checked
    let doesInclude = selectedLOD.includes(newVal)
    let newItems = selectedLOD.slice();
    if (isChecked && !doesInclude) {
      newItems = [...selectedLOD, newVal]
    } else if (!isChecked && doesInclude) {
      newItems = selectedLOD.filter(e => e !== newVal)
    }
    setSelectedLOD(newItems)
  }

  const handleSetFos = (e: ChangeEvent<HTMLInputElement>) => {
    let newVal = e.target.value
    let isChecked = e.target.checked
    let doesInclude = selectedFos.includes(newVal)
    let newItems = selectedFos.slice();
    if (isChecked && !doesInclude) {
      newItems = [...selectedFos, newVal]
    } else if (!isChecked && doesInclude) {
      newItems = selectedFos.filter(e => e !== newVal)
    }
    setSelectedFos(newItems)
  }

  const handleSelectedFormatChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newVal = e.target.value
    let isChecked = e.target.checked
    let doesInclude = selectedFormat.includes(newVal)
    let newItems = selectedFormat.slice();
    if (isChecked && !doesInclude) {
      newItems = [...selectedFormat, newVal]
    } else if (!isChecked && doesInclude) {
      newItems = selectedFormat.filter(e => e !== newVal)
    }
    setSelectedFormat(newItems)
  }

  const handleProductsReset = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // Reset Pagination to page one
    // setCurrentPage(1)
    // Reset to the initial set of products
    setCourses(view.courses)
    setSelectedLOD([]);
    setSelectedFos([]);
    setSelectedFormat([]);
    setFilterText(null);
    // setProductCategory('all');
    // document.querySelector('#search').scrollIntoView({ behavior: "smooth" });
  }

  const handleCoursesFilter = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setCourses(filteredCourses)
    setShowFilterPanel(false)
    // document.querySelector('#search').scrollIntoView({
    //   behavior: 'smooth'
    // });
  }

  // console.log({selectedLOD})
  return (
    <div className='flex flex-col lg:flex-row'>

      <aside className='w-auto mb-8 mr-0 space-y-8 lg:mx-6 lg:mr-8 lg:mb-0 lg:w-64 xl:w-80 md:flex-none' ref={ref}>

        <form onSubmit={handleCoursesFilter} onReset={handleProductsReset}>
          {/* Search input */}
          <div className="flex flex-col space-y-2">
            <label className="text-xs font-bold tracking-normal text-gray-500 uppercase">Search</label>
            <div className="w-full">
              <input
                className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                type="search"
                placeholder="Search our course titles and descriptions"
                onChange={e => setFilterText(e.target.value)}
                // defaultValue={filterText}
                value={filterText ? filterText : ''}
              />
            </div>
          </div>

          <div>
            {/* Level of Difficulty */}

            <div className='mt-6'>
              <Accordion title='TRACK + Level of Difficulty' titleClasses='text-xs font-bold tracking-normal text-gray-500 uppercase hover:text-gray-800'>
                <fieldset>
                  <legend className="hidden text-xs font-bold tracking-normal text-gray-500 uppercase">Level of Difficulty</legend>
                  <div className="grid grid-cols-1 mt-3 gap-x-2 gap-y-2">

                    {LODList && LODList.map(lod =>
                      <div className="flex items-start col-span-1" key={lod.id}>
                        <input name={lod.id} id={lod.id} value={lod.id} type="checkbox" className="inline-block w-4 h-4 mt-1 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                          onChange={e => handleSetLOD(e)} checked={selectedLOD?.includes(lod.id) && true} />
                        <label htmlFor={lod.id} className="ml-[10px] text-base text-gray-700">{lod.title}</label>
                      </div>)}

                  </div>
                </fieldset>
              </Accordion>
            </div>


            {/* Field of Study  */}

            <div className='mt-6'>
              <Accordion title='Field of Study' titleClasses='text-xs font-bold tracking-normal text-gray-500 uppercase hover:text-gray-800'>
                <fieldset>
                  <legend className="hidden text-xs font-bold tracking-normal text-gray-500 uppercase">Field of Study</legend>
                  <div className="grid grid-cols-1 mt-3 gap-x-2 gap-y-2">
                    {FosList && FosList.map(fos =>
                      <div className="flex items-start col-span-1" key={fos._id}>
                        <input name={`fos[${fos._id}]`} id={`fos[${fos._id}]`} value={fos._id} type="checkbox" className="inline-block w-4 h-4 mt-1 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                          // defaultChecked={selectedFos?.includes(fos._id) && true}
                          onChange={e => handleSetFos(e)} checked={selectedFos?.includes(fos._id) && true} />
                        <label htmlFor={`fos[${fos._id}]`} className="ml-[10px] text-base text-gray-700">{capitalizeFirstLetter(fos.title)}</label>
                      </div>)}
                  </div>
                </fieldset>
              </Accordion>
            </div>

            {/* Available Formats  */}
            <div className='pt-6 mt-6 border-t-2 border-gray-200'>

              <Accordion title='Available Formats' titleClasses='text-xs font-bold tracking-normal text-gray-500 uppercase hover:text-gray-800'>
                <fieldset>
                  <legend className="hidden text-xs font-bold tracking-normal text-gray-500 uppercase">Available Formats</legend>
                  <div className="grid grid-cols-1 mt-3 text-base gap-x-2 gap-y-2">
                    {AvailableFormats && AvailableFormats.map(format =>
                      <div className="flex items-start col-span-1" key={format._id}>
                        <input name={`format[${format._id}]`} id={`format[${format._id}]`} value={format._id} type="checkbox" className="inline-block w-4 h-4 mt-1 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                          // defaultChecked={selectedFormat?.includes(format._id) && true}
                          onChange={e => handleSelectedFormatChange(e)} checked={selectedFormat?.includes(format._id) && true} />
                        <label htmlFor={`format[${format._id}]`} className="ml-[10px] text-base text-gray-700">{capitalizeFirstLetter(format.title)}</label>
                      </div>)}
                  </div>
                </fieldset>
              </Accordion>
            </div>

          </div>

          {/* Search Button */}

          <div className='flex flex-row items-center justify-between mt-8'>
            <div className=''>
              <p className='text-xs text-gray-500'>{courses.length} found</p>
            </div>


            <div className='flex space-x-4'>
              <button type="submit" className="flex items-center justify-center px-3 py-2 text-xs font-medium text-white transition-all border border-transparent rounded-md shadow-sm bg-brand-1-500 hover:bg-brand-1-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1-200" >Search</button>

              <button className="px-3 py-2 text-gray-500" type="reset">Clear</button>

            </div>
          </div>
        </form>
      </aside>

      {/* sm:divide-y sm:divide-gray-200 */}
      <div className='px-6 py-8 space-y-8 bg-gray-200 rounded-md md:flex-auto' id="search">
        {(courses && courses.length > 0) ? courses.map(course => <Teaser key={course._id} course={course} filterText={filterText} />) :
          <div className=''>
            <p className='px-8 pt-6 pb-8 text-3xl font-bold text-gray-700 bg-white rounded-md shadow-sm'>No results found</p>
          </div>
        }
      </div>

    </div>
  )
}

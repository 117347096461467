
type GridProps = React.PropsWithChildren<{
  gridSize?: number;
}>;
export function Grid({ gridSize, children }: GridProps) {
  if( !gridSize ){
    return <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-8 md:gap-x-12 gap-y-12">{children}</div>
  } else if(gridSize === 2) {
    return <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-8 md:gap-x-12 gap-y-12">{children}</div>
  } else if(gridSize === 4) {
    return <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-x-8 md:gap-x-12 gap-y-12">{children}</div>
  } else if(gridSize % 3 === 0) {
    return <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-8 md:gap-x-12 gap-y-12">{children}</div>
  } else if(gridSize % 4 === 2) {
    return <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 sm:gap-x-8 md:gap-x-12 gap-y-12">{children}</div>
  } else {
    return <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 sm:gap-x-8 md:gap-x-12 gap-y-12">{children}</div>
  }
}

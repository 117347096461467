import Image from 'next/image';
import { Linker } from '@/components/content/linker'
import { HiChevronRight as RightChevronIcon } from 'react-icons/hi';

import { config } from '@/lib/sanity.config'
import imageUrlBuilder from '@sanity/image-url'
const builder = imageUrlBuilder(config)


export function OurInstructors({view}) {
  const instructors = view?.instructorsPageView;
  const bgImage = (val) => {
    return val ? `${builder.image(val).auto('format').width(1200).url()}` : null
  }
  const bgImageBlur = (val) => {
    return val ? `${builder.image(val).auto('format').width(10).url()}` : null
  }
  // console.log({instructors})
  return (

    <ul role="list" className="max-w-5xl mx-auto space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
      {instructors.sort((a, b) => a.sortOrder - b.sortOrder).map(({_id, firstName, lastName, slug, professionalTitle, image}) => (
        <li key={_id} className="px-6 py-10 text-center rounded-lg bg-brand-2-700 xl:px-10 xl:text-left">
          <div className="space-y-6 xl:space-y-10">

            {image ? <div className='flex items-center justify-center w-full'><Linker url={`/our-instructors/${slug}`}><div className="relative w-32 h-32 overflow-hidden transition-all bg-gray-200 border-4 rounded-full border-brand-2-600 hover:border-brand-2-500">
        <Image
            src={bgImage(image)}
            alt={`${firstName} ${lastName} `}
            blurDataURL={bgImageBlur(image) || ''}
            placeholder={bgImageBlur(image) ? 'blur' : 'empty'}
            layout="fill"
            objectFit="cover"
          /></div></Linker></div> : (
            <div className='flex items-center justify-center w-full'><Linker url={`/our-instructors/${slug}`}><div className="relative flex items-center justify-center w-32 h-32 overflow-hidden transition-all border-4 rounded-full bg-brand-1-500 border-brand-2-600 hover:border-brand-2-500">
             <span className='-space-x-1 text-4xl font-bold text-gray-400'> <span>{firstName.slice(0, 1).toUpperCase()}</span> <span>{lastName.slice(0, 1).toUpperCase()}</span> </span>
            </div></Linker></div>
          )}

            <div className="flex flex-col space-y-2 text-center">
              <div className="space-y-1 font-medium leading-6">
                <h3 className="text-2xl font-semibold text-white lg:text-3xl">{firstName} {lastName}</h3>
                <p className="text-base text-gray-300 lg:text-lg">{professionalTitle}</p>
              </div>

              <div className='pt-4 group'>
                <p className='flex items-center justify-center w-full text-white '><Linker url={`/our-instructors/${slug}`} classes='text-sm text-gray-400 transition-all group-hover:text-white'>view my profile</Linker> <RightChevronIcon className='relative text-gray-400 transition-all group-hover:translate-x-1 group-hover:text-white' /></p>
              </div>

            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

import PropTypes from 'prop-types'
import { Contact } from '@/components/content/view/contact'
import { JobApp } from '@/components/content/view/job-app'
import { ContentView } from '@/components/content/view/content-view/'
import { TeamView } from '@/components/content/view/team/'
import { Subscribe } from '@/components/services/mailchimp/subscribe'
import { UpcomingEvents } from '@/components/content/view/events/'
import { LatestPosts } from '@/components/content/view/posts/'

{/* RWR CUSTOM COMPONENT : START 🟢 */}
import { TrainingLibrary } from '@/components/content/view/rwr/training-library'
import { MembershipTableView } from '@/components/content/view/rwr/membership-table'
import { OurInstructors } from '@/components/content/view/rwr/our-instructors'
import { CustomerReviews } from '@/components/content/view/rwr/customer-reviews'
{/* RWR CUSTOM COMPONENT : END 🛑 */}

ViewComponent.propTypes = {
  contentView: PropTypes.string.isRequired,
  webform: PropTypes.object,
  view: PropTypes.object,
  filter: PropTypes.string
}

export function ViewComponent(props: { contentView: string, webform: any; view: any; filter: any; }) {
  const { contentView, webform, view, filter } = props
  if (contentView === 'componentViewContact') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <Contact webform={webform} />
      </section>
    )
  } else if (contentView === 'componentViewJobApp') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <JobApp webform={webform} />
        </section>
      )
  } else if (contentView === 'componentViewPosts' || contentView === 'componentViewEvents') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <ContentView view={view} />
      </section>
    )
  } else if (contentView === 'componentViewTeam') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <TeamView view={view} />
      </section>
    )
  } else if (contentView === 'componentViewLatestPosts') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <LatestPosts />
      </section>
    )
  } else if (contentView === 'componentViewUpcomingEvents') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <UpcomingEvents />
      </section>
    )
  } else if (contentView === 'componentMCSubForm') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        {/* <Subscribe /> */}
      </section>
    )
  } else if (contentView === 'componentTrainingLibrary') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <TrainingLibrary view={view} />
      </section>
    )
  } else if (contentView === 'componentMembershipTable') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <MembershipTableView view={view} />
      </section>
    )
  } else if (contentView === 'componentOurInstructors') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <OurInstructors view={view} />
      </section>
    )
  } else if (contentView === 'componentCustomerReviews') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <CustomerReviews view={view} />
      </section>
    )
  } else {
    return null
  }
}

import PropTypes from 'prop-types'
import React from 'react'
import { parseISO, format } from 'date-fns'
import { HiCalendar as CalendarIcon } from 'react-icons/hi';

CourseDates.propTypes = {
  title: PropTypes.string,
  courses: PropTypes.array
}

export function CourseDates(props: { title: any; courses: any; }) {

  const { title, courses } = props;

  return (
    <div className="">
      <dt className="text-sm font-bold tracking-normal text-gray-500 uppercase">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900">
        <span className="flex flex-col flex-wrap mt-2 space-y-3">
          {courses?.map(course =>
            <div className="flex items-center mt-1 text-base text-gray-700" key={course._key}><span className='mr-2'><CalendarIcon /></span>{format(parseISO(course.startDate), 'LLL, do. yyyy')} - {format(parseISO(course.endDate), 'LLL, do. yyyy')}</div>
          )}
        </span>
      </dd>
    </div>
  )

}

import React, { useState } from 'react';

export const Accordion = ({ title, titleClasses, children }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <button className="flex items-center justify-between w-full py-3 text-sm text-gray-500 transition-all ease-in-out bg-white hover:text-gray-700" onClick={() => setIsActive(!isActive)}>
        <span className={titleClasses}>{title}</span>
        <span className=''>{isActive ? <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M5 10a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1z" clipRule="evenodd"/></svg> : <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1z" clipRule="evenodd"/></svg>}</span>
      </button>
      <div className={`transition-all overflow-hidden ${isActive ? 'opacity-100 visible ease-in max-h-[80em]' : 'opacity-0 invisible  ease-in-out max-h-0'}`}>{children}</div>
    </div>
  );
};

import PropTypes from 'prop-types'
import { Teaser } from '@/components/content/view/rwr/membership-table/teaser'
import { ExclamationIcon } from '@heroicons/react/solid'
import { useUser } from '@/utils/useUser';

MembershipTableView.propTypes = {
  data: PropTypes.array
}

export function MembershipTableView(props: { view: any; }) {
  const { membershipPageView } = props?.view
  const { membershipTable, membershipFooter } = membershipPageView
  // console.log({ membershipTable })
  const { user, isLoading, subscription } = useUser();

  // lg:max-w-4xl
  // console.log({user})
  return (<>
    {(!isLoading && !user) &&
      <div className="p-4 rounded-md bg-yellow-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="w-5 h-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            {/* pt-4 text-xs  */}
            <h3 className="text-sm font-bold tracking-normal text-yellow-800 uppercase">Attention</h3>
            <div className="mt-2 text-base text-yellow-700">
              <p>
              You must first create a Free Basic Membership account and be logged in to purchase the Full Membership.
              </p>
            </div>
          </div>
        </div>
      </div>}
    {/* Table */}
    <div className="mt-2 space-y-4 sm:mt-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
      {membershipTable.map(({ _key, title, ctaAction, body, ctaText, ctaLink, description, priceLabel, stripePriceID }) => (
        <Teaser key={_key} title={title} ctaAction={ctaAction} body={body} ctaText={ctaText} description={description} priceLabel={priceLabel} ctaLink={ctaLink} stripePriceID={stripePriceID} />
      ))}
    </div>

    {/* Footer */}
    {/* <div className='mt-10'>
      {membershipFooter.map(({ _key, title, ctaAction, ctaLink, body, ctaText, description, priceLabel, stripePriceID }) => (
        <Teaser key={_key} title={title} ctaAction={ctaAction} body={body} ctaText={ctaText} description={description} priceLabel={priceLabel} ctaLink={ctaLink} smView={true} stripePriceID={stripePriceID} />
      ))}
    </div> */}

  </>)
}

import PropTypes from 'prop-types'
import { PortableText } from '@/lib/sanity'
import serializers from '@/components/layout/content-components/serializers'

TextWrapper.propTypes = {
  body: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
}

function BodyText({ blocks }) {
  // console.log({blocks})
  // TODO: Test this conditional logic out more to make sure it's SOLID
  // && blocks[0]?.children?.length
  return (blocks?.length ) ? <PortableText blocks={blocks} serializers={serializers} /> : null
}

export function TextWrapper(props: { body: any; fullWidth: any; layout: boolean; }) {

  const { body, fullWidth, layout } = props
  // console.log({ body })
  if(layout) {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className={`${fullWidth ? 'max-w-none' : ''} relative prose bg-white md:prose-lg lg:prose-xl`}>
        <BodyText blocks={body} />
        </div>
      </section>
    )
  } else {
    return (
        <div className={`${fullWidth ? 'max-w-none' : ''} relative prose bg-white md:prose-lg lg:prose-xl`}>
        <BodyText blocks={body} />
        </div>
    )
  }
}

import React from 'react'
import { Review } from '@/components/content/course/review'
import { Linker } from '@/components/content/linker';

export function CustomerReviews({ view }) {
  // console.log({view})
  const courses = view?.courses.filter(course => course?.reviews && course?.reviews?.length >= 1) || []
  // console.log({ courses })

  return (
    <div>
      <ul className='grid grid-cols-1 divide-y-2 space-y-14'>
        {courses.map(({ _id, slug, title, reviews }) =>
          <li key={_id} className='not-first:pt-14'>
            <h2 className='mb-4 text-3xl font-bold'><Linker classes="underline decoration-gray-100 underline-offset-2 hover:decoration-gray-600 transition-all" url={`/course/${slug}`}>{title}</Linker></h2>
            {(reviews && reviews?.length > 0) && reviews.map(({ _id, date, instructors, review, title }) => <Review date={date} instructors={instructors} review={review} title={title} key={_id} />)}
          </li>
        )}
      </ul>
    </div>
  )
}

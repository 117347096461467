import PropTypes from 'prop-types'
import Icon from '@/components/shared/react-icons-wrapper'
import { Linker } from '@/components/content/linker'
import { transformToUpperCase } from '@/utils/helpers'

Featurette.propTypes = {
  featuretteStyle: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.any,
}

const TitleComponent = ({ linkUrl, title }) => {
  if (linkUrl) {
    return <h3 className="text-lg font-bold tracking-tight text-brand-2-500"><Linker url={linkUrl} classes="quickline">{title}</Linker></h3>
  } else {
    return <h3 className="text-lg font-medium tracking-tight text-brand-2-700">{title}</h3>
  }
}

export function Featurette(props: { featuretteStyle: string; url: string; title: string; text: string; icon: any; }) {

  const { featuretteStyle, title, url, text, icon } = props

  if (featuretteStyle === 'centered') {
    return (
      <div className="pt-4 mt-4 text-center md:mt-6">
        <div className="flow-root px-4 pb-8 bg-gray-100 rounded-lg">
          <div className="-mt-6">

            {/* Icon */}
            {icon?.name && (
              <div className="mb-6">
                <span className="inline-flex items-center justify-center p-3 rounded-md shadow-lg bg-brand-1-500">
                  <Icon name={transformToUpperCase(icon?.name, ['-'])} size={'2em'} color={'white'} aria-hidden="true" />
                </span>
              </div>
            )}

            {/* Title */}
            {title && <TitleComponent title={title} linkUrl={url} />}

            {/* Text */}
            {text && <div><p className="mt-3 text-base text-gray-600">{text}</p></div>}

          </div>
        </div>
      </div>
    )
  } if (featuretteStyle === 'side-by-side') {
    return (
      <div className="flow-root px-6 text-left">
        <div className="flex flex-row">

          {/* Icon */}
          {icon?.name && (
            <div className="mb-6">
              <span className="inline-flex items-center justify-center text-brand-1-500">
                <Icon name={transformToUpperCase(icon?.name, ['-'])} size={'2em'} aria-hidden="true" />
              </span>
            </div>
          )}

          <div className="ml-6">
            {/* Title */}
            {title && <TitleComponent title={title} linkUrl={url} />}

            {/* Text */}
            {text && <div><p className="mt-3 text-base text-gray-600">{text}</p></div>}
          </div>

        </div>
      </div>
    )
  } else { // 'stacked' default
    return (
      <div className="flow-root px-6 text-left">

        {/* Icon */}
        {icon?.name && (
          <div className="mb-2">
            <span className="inline-flex items-center justify-center text-brand-1-500">
              <Icon name={transformToUpperCase(icon?.name, ['-'])} size={'2em'} aria-hidden="true" />
            </span>
          </div>
        )}

        {/* Title */}
        {title && <TitleComponent title={title} linkUrl={url} />}

        {/* Text */}
        {text && <div><p className="mt-3 text-base text-gray-600">{text}</p></div>}

      </div>
    )
  }

}
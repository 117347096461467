import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Linker } from '@/components/content/linker'
import { isEmpty } from '@/utils/helpers';
import toast from "@/components/toast";
import { PortableText } from '@/lib/sanity'
import serializers from '@/components/layout/content-components/serializers'
import { Loader } from '@/components/shared/loader';
import { postData } from '@/utils/helpers';
import { getStripe } from '@/utils/stripe-client';
import { useUser } from '@/utils/useUser';
// import { Price } from 'types';
// import { getActiveProductsWithPrices } from '@/utils/supabase-client';

export function Teaser(props: { title: string; ctaAction: string; ctaLink: string; body: any; ctaText: string; description: string; priceLabel: string; smView?: boolean; stripePriceID: string; }) {
  const { title, ctaAction, ctaText, ctaLink, body, description, priceLabel, stripePriceID, smView } = props;


  const [priceIdLoading, setPriceIdLoading] = useState<string>();
  const { user, isLoading, subscription } = useUser();
  const [loading, setLoading] = useState(true);
  const notify = useCallback((type, message) => {
    toast({ type, message });
  }, []);

  const handleClick = async (priceID: string) => {
    if (!user) {
      notify("warning", 'You must first create a Free Basic Membership account and be logged in to purchase the Full Membership.');
      return
    }
    // console.log({ price })
    try {
      const { sessionId } = await postData({
        url: '/api/stripe/create-checkout-session',
        data: { priceID: priceID }
      });

      const stripe = await getStripe();
      stripe?.redirectToCheckout({ sessionId });
    } catch (error) {
      // return alert((error as Error)?.message);
      console.log({ error })
      notify("warning", error?.message || 'There was an error, please try again.');
      return
    } finally {
      setPriceIdLoading(undefined);
    }
  }

  const redirectToCustomerPortal = async () => {
    if (!user) {
      notify("warning", 'You must first create a Free Basic Membership account and be logged in to purchase the Full Membership.');
      return
    }
    setLoading(true);
    try {
      const { url, error } = await postData({
        url: '/api/stripe/create-portal-link'
      });
      window.location.assign(url);
    } catch (error) {
      if (error) return alert((error as Error).message);
    }
    setLoading(false);
  };

  const CurrentPlan = () => {
    if(!isEmpty(user) && !isEmpty(subscription) && stripePriceID === subscription?.prices?.id )  {
      return true
    } else if (!isEmpty(user) && ctaLink === '/app/join' && isEmpty(subscription)) {
      return true
    } else {
     return false
    }
  }

  if (isLoading) {
    return <Loader />
  }
  // console.log({priceLabel})
  return (
    <div className={`flex flex-col justify-between border border-gray-200 rounded-lg shadow-sm relative ${CurrentPlan() ? 'border-indigo-500 border-2' : ''}`}>
      {CurrentPlan() && <span className=" inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-indigo-500 text-white absolute  left-1/2 -translate-x-1/2 -top-[11px]">
        Current Plan
      </span>}
      <div>
        <div className="px-6 pt-8">
          {title && <h2 className="text-3xl font-semibold text-brand-2-600">{title}</h2>}
          {description && <p className="mt-6 text-sm text-gray-500">{description}</p>}
          {priceLabel && <p className="mt-2 text-2xl font-extrabold text-gray-900">{priceLabel}</p>}
        </div>

        {body && <div className="px-6 pt-4 ">
          <h3 className="pt-4 text-xs font-bold tracking-normal text-gray-500 uppercase border-t-2 border-gray-200">What&apos;s included</h3>
          <div className='mt-4 whatsIncluded'>
            <PortableText blocks={body} serializers={serializers} />
          </div>
        </div>}
      </div>

      {(ctaAction && ctaText) && <div className="px-6 pt-4 pb-8">
        {(ctaAction === 'link') && <Linker
          url={ctaLink}
          classes={`${smView ? 'inline-block' : 'block w-full'} px-4 py-2 text-sm font-semibold text-center text-white ${CurrentPlan() ? 'bg-indigo-500' : 'bg-gray-800'} border border-${CurrentPlan() ? 'indigo' : 'gray'}-800 rounded-md hover:bg-${CurrentPlan() ? 'indigo' : 'gray'}-900`}>
          {CurrentPlan() ? 'Current Plan' : ctaText}
        </Linker>}

        {(ctaAction === 'pay' && stripePriceID) && <button type="button" disabled={isLoading} onClick={() => handleClick(stripePriceID)} className={`${smView ? 'inline-block' : 'block w-full'} px-4 py-2 text-sm font-semibold text-center text-white ${CurrentPlan() ? 'bg-indigo-500' : 'bg-gray-800'} border border-${CurrentPlan() ? 'indigo' : 'gray'}-800 rounded-md hover:bg-${CurrentPlan() ? 'indigo' : 'gray'}-900`}>{CurrentPlan() ? 'Manage Your Account' : ctaText}</button>}
      </div>}

    </div>
  )
}

Teaser.propTypes = {
  title: PropTypes.string,
  body: PropTypes.array,
  ctaAction: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  description: PropTypes.string,
  priceLabel: PropTypes.string,
  stripePriceID: PropTypes.string,
  smView: PropTypes.bool || false,
}

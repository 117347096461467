import React from 'react'
import { Linker } from '@/components/content/linker'
import { portableTextToPlainText, truncate } from '@/utils/helpers'
import { Highlighted } from '@/components/content/view/highlight';
import { Duration } from '@/components/content/course/duration'
import { Level } from '@/components/content/course/level'
import { FieldOfStudy } from '@/components/content/course/field-of-study'
import { CourseDates } from '@/components/content/course/course-dates'
import { AvailableFormats } from '@/components/content/course/available-formats'
import { MyCourseWidget } from '@/components/content/view/rwr/training-library/my-course-widget'

// const TeaserDetails = ({ title, value }) => {
//   return (
//     <div className="sm:col-span-1">
//       <dt className="text-sm font-bold tracking-normal text-gray-500 uppercase">{title}</dt>
//       <dd className="mt-1 text-base text-gray-700">{value}</dd>
//     </div>
//   )
// }

export function Teaser({ course, filterText }) {

  const { _id, slug, title, duration, fieldOfStudy, formats, body, liveInPersonCourses, trackLevel } = course;
  return (
    <div className='px-8 pt-6 pb-8 bg-white rounded-md shadow-sm'>

    <div className='flex flex-col md:flex-row md:items-center'>
      <MyCourseWidget courseID={_id} />
      <h3 className='text-3xl font-semibold'><Linker url={`/course/${slug}`} classes="underline decoration-gray-100 underline-offset-2 hover:decoration-gray-600 transition-all">
      <Highlighted highlight={filterText} text={title} />
      </Linker></h3>
    </div>


      {(body && body.length > 0) && <p className='mt-3 prose md:prose-lg lg:prose-xl max-w-none'>

        <Highlighted highlight={filterText} text={truncate(portableTextToPlainText(body))} /> </p>}

      <div className='grid grid-cols-1 mt-6 gap-x-4 gap-y-6 lg:grid-cols-2'>

        {trackLevel && <div className='sm:col-span-1'><Level title='Level of Difficulty' value={trackLevel} /></div>}

        {duration && <div className='sm:col-span-1'><Duration title='Duration' value={duration} /></div>}

        {fieldOfStudy?.title && <div className='sm:col-span-1'><FieldOfStudy title='Field of Study' value={fieldOfStudy.title} /></div>}

        {(liveInPersonCourses && liveInPersonCourses.length >= 1) &&
          <div className='sm:col-span-1'><CourseDates title='Live In-Person Courses' courses={liveInPersonCourses} />
          </div>}
{/*
        {(liveOnlineCourses && liveOnlineCourses.length >= 1) &&
          <div className='sm:col-span-1'><CourseDates title='Live Online Courses' courses={liveOnlineCourses} />
          </div>}
*/}
        {(formats && formats.length > 0) &&
          <div className='sm:col-span-2'><AvailableFormats title='Available Formats' formats={formats} /></div>}
{/*
          <div className='sm:col-span-2'>
              <p className='text-xs text-gray-400'>{_id}</p>
          </div>
        */}
      </div>

    </div>
  )
}
